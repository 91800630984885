import { getUploadConfig } from './config';
import apiHelper from './index';

const uploadVideo = async (
  payload,
  spinner,
  onUploadProgress = () => {},
  queryParams = {}
) => {
  return await apiHelper.upload(
    `organization/task/upload/`,
    payload,
    spinner,
    queryParams,
    getUploadConfig(onUploadProgress)
  );
};

export default {
  uploadVideo,
};
